import React from 'react';
import {fetchData} from '../../fetch/fetchData.js'
import './proyecto.css';

const apiData = fetchData("https://www.davmund.cl/mantenedor/servicios/listaProyectos.php");

const Proyecto = (props) => {
    const data = apiData.read();
    return(
        <>
            {data?.listaproyectos.map(
                (proyecto,index)=>{
                    if(proyecto.id === props.id){return<>
                    <div key={`render_${proyecto.id}`} id={`render_${proyecto.id}`} className='contenedor_text'>
                    <h2>{proyecto.name}</h2>
                    <span className='contenedor_info'>
                        <h6>{proyecto.name !== '' ? proyecto.canales: ''}</h6>
                        <h6>{proyecto.cliente !== '' ? proyecto.cliente : ''}</h6>
                    </span>
                    <span>{
                        proyecto.description.split('<br />').map(
                            (parrafo,index) => {
                                return <p key={`p_${parrafo[index]}`}>{parrafo}</p>
                            }
                        )
                    }</span>
                    </div>
                    <div className='img'>
                        {
                            proyecto.images.split(',').map(
                            (imagen,index)=> {
                                return <img alt={`imagen_${proyecto.name}`} key={proyecto[index]} src={`./images/${props.cat}/${proyecto.name}/${imagen}`}></img>
                            })
                        }
                    </div>
                    </>
                    }
                })
            }
        </>
    )
}

export default Proyecto;