import './about.css';
import React, { Suspense, useEffect } from 'react';
import {fetchData} from '../../fetch/fetchData.js';
import Pod from '../../componentes/pod/Pod';

const apiData = fetchData("https://davmund.cl/mantenedor/servicios/listaCursos.php");
const apiData2 = fetchData("https://davmund.cl/mantenedor/servicios/listaExperiencias.php");

const About = () => {
  const data = apiData.read();
  const data2 = apiData2.read();
  function scrollingWindows (){
    return window.scrollY > 0 ? window.scroll({top: 0,behavior: "smooth"}):null;
  }
  useEffect(()=>{
    scrollingWindows()
  })
  return (
    <Suspense fallback={<>Cargando...</>}>
    <div className="bloque_aboutme">
    <div className="bloque_aboutme_img" style={{backgroundImage: `url('./assets/about/moto_tratada.jpg')`}}></div>
    <div id="descripcion" className="section_aboutme">
        <div className="section_aboutme_descripcion">
            <h2>Me defino...</h2>
            <div>
                <h5 className="sub_desc">Como profesional</h5>
                <p>Diseñador Gráfico Publicitario de la Universidad Santo Tomás, con más de 13 años de experiencia en
                diseño digital, integración y diseño interfaz de usuario, principalmente en rubros como comercio electrónico y Ti.</p>  
                <p>Como profesional, me destaco por ser metódico e independiente, como también, por mi capacidad para trabajar en equipo y por estar siempre orientado a resultados.</p>
                <h5 className="sub_desc">Como persona</h5>
                <p>Dentro de mis intereses y hobbies están la fotografía, la ilustración, el deporte y las motos.</p>
            </div>
            
        </div>
    </div>
</div>
<div className="body_aboutme">

    <div className="section_quehago">
        <div className='titulo_queHago'>
            <h2>¿A qué me dedico?</h2>
        </div>
        <section>
            <div className="section_quehago_column" style={{backgroundImage: `url('../assets/about/about_2.jpg')`}}>
                <div>
                    <h2 className="section_quehago_head">Diseñador UX/UI</h2>
                    <p className="section_quehago_text">
                        Tengo un enfoque en la solución de problemas, la entrega de valor y la provisión de soluciones eficientes y eficaces para los usuarios.
                        Para esto implemento design thinkin, benchmarks (en todos sus tipos), entrevistas a usuarios, test con usuarios (tanto moderados como no moderados)
                         y heurísticas. Mis herramientas favoritas son Figma, Scketch, Maze y Hotjar.
                    </p>
                    <span className="section_quehago_skills">
                        <h5>Skills: </h5>
                        <img alt="classNamiconeicon" src="./assets/icons/figma.png" />
                        <img alt="icon_sketch" src="./assets/icons/sketch.png" />
                        <img alt="icon_maze" src="./assets/icons/maze.png" />
                        <img alt="icon_hotjar" src="./assets/icons/hotjar.png" />
                        <img alt="icon_miro" src="./assets/icons/miro.png" />
                        <img alt="icon_jira" src="./assets/icons/jira.png" />
                        <img alt="icon_scrum" src="./assets/icons/scrum.png" />
                    </span>
                </div>
            </div>
            <div className="section_quehago_column"  style={{backgroundImage: `url('../assets/about/about_1.jpg')`}}>
                <div>
                    <h2 className="section_quehago_head">Diseñador Gráfico</h2>
                    <p className="section_quehago_text">
                        He trabajado en diseño de marca, impresos, digital y 
                        gráfica para máqinas de vending. Como diseñador, me preocupo de involucrar a mis clientes, 
                        tratando de familiarizarme con sus necesidades y objetivos.
                        Los programas que manejo son Photoshop e illustrator, en un nivel avanzado e Indesign, After Effetcs y Premier en un nivel medio.<br />
                    </p>
                    <span className="section_quehago_skills">
                        <h5>Skills: </h5>
                        <img alt="icon_photosho" src="./assets/icons/phoshop.png" />
                        <img alt="icon_illustrator" src="./assets/icons/illustrator.png" />
                        <img alt="icon_indesign" src="./assets/icons/indesign.png" />
                        <img alt="icon_after" src="./assets/icons/after.png" />
                        <img alt="icon_premier" src="./assets/icons/premier.png" />
                    </span>
                </div>
            </div>
            <div className="section_quehago_column"  style={{backgroundImage: `url('../assets/about/about_3.jpg')`}}>
                <div>
                    <h2 className="section_quehago_head">Front</h2>
                    <p className="section_quehago_text">
                        Desde mis inicios he estado ligado a la web, y me desenvuelvo muy bien en HTML, 
                        CSS, Javascript y Jquery. Además, he hecho cursos para profundizar en desarrollo, 
                        aprendiendo sobre React js, mysql y Java.
                    </p>
                    <span className="section_quehago_skills">
                        <h5>Skills: </h5>
                        <img alt="icon_css" src="./assets/icons/css.png" />
                        <img alt="icon_html" src="./assets/icons/html.png" />
                        <img alt="icon_javascript" src="./assets/icons/javascript.png" />
                        <img alt="icon_jquery" src="./assets/icons/jquery.png" />
                        <img alt="icon_react" src="./assets/icons/react.png" />
                    </span>
                </div>
            </div>
        </section>
        <a className='boton_cv' href="img/Cv.pdf" target="_blank"> Descargar Cv </a>{/* boton descarga */}

    </div>

    <div className="section_aboutme_experiencia">
        <div className="section_aboutme_block">
            <h2>Educación, diplomado y cursos</h2>
            <p className="experiencia_bajada">Algunos cursos y diplomados que he tomado duarante mi carrera para profundizar y ampliar conocimientos.</p>
            <div className="lineatiempo" id="educacion" data-targ="educacion">
              {data?.listacursos.map(//mapeando la lista de cursos
                            (ed)=>{
                                return <Pod key={ed.id_ed} objeto={ed} type={'ed'}></Pod>
                            }
                        )}
            </div>
        </div>
        <div className="section_aboutme_block">
            <h2>Experiencia profesional</h2>
            <p className="experiencia_bajada">En mi carrera he estado muy ligado al diseño web y digital, con algunos extraños casos en que he podido ver temas de branding o ilustración.</p>
            <div className="lineatiempo" id="experiencia" data-targ="experiencia">
            {data2?.listaexperiencias.map(//mapeando la lista de cursos
                            (exp)=>{
                                return <Pod key={exp.id_exp} objeto={exp} type={'exp'} />
                            }
                        )}
            </div>
        </div>
    </div>
    </div>
    </Suspense>
  );
}

export default About;