import React/* , {useState,useEffect} */ from 'react';
import './slide.css';

const Slide = () =>{
    /* const slides = [{id:0,url:'g_1.jpg'},{id:1,url:'g_2.jpg'},{id:2,url:'g_3.jpg'}]; */
    /* const [estadoSlide,cambiaEstadoSlide] = useState(0); */

    /* useEffect(()=>{
       let interval =  setInterval(
            (e) => {
                let galeria = document.getElementById("tira");
                let percen = (estadoSlide*-100);
                if(estadoSlide==slides.length-1){
                    cambiaEstadoSlide(0);
                }else{
                    cambiaEstadoSlide(estadoSlide+1);
                }
                galeria.style.transform = "translateX("+percen+"%)";
            }
        , 5000);

        return () => {clearInterval(interval)};
    }) */
    
    return(
        <div className="biografia">
            <div id="biografiaImg" className="biografia_img">
            <div key="slide_key" id="slide" className="slideMain">
                <div className="slide_imagen" style={{backgroundImage:'url(./assets/galeria/maqueta2.jpg)'}}></div>
                <img className='biografia_caracter' src='./assets/galeria/yo.png'/>
                {/* <div key="slide_tira" id="tira" className="tira">
                    {
                        slides.map((item)=>{
                            return <div key={item.id} className="slide_imagen" style={{backgroundImage : `url(./assets/galeria/${item.url})`}}></div>
                        })
                    }
                </div> */}
            </div>
            </div>
            <div className="biografia_textos">
                <div className="biografia_nombre">
                    <h1>David Muñoz Trollund</h1>
                    <h2>Diseñador UX/UI - Diseñador Gráfico</h2>
                </div>
                <div className="biografia_sobremi">
                    <p> 
                        Diseñador UX/UI con foco en el usuario, siempre buscando generar valor de cara a las personas y eficiencia para el negocio.
                    </p>
                </div>
                <ul className='biografia_contact'>
                    <li><span><img alt='Icono Linkedin' src="./assets/icons/linkedin.png" /></span><a href="https://www.linkedin.com/in/david-muñoz-trollund-3bb111a" target="_blank" rel="noreferrer"><p>Linkedin</p></a></li>
                    <li><span className="material-symbols-outlined">call</span><p>(+56) 9 4211 0098</p></li>
                    <li><span className="material-symbols-outlined">mail</span><p>david.trollund@gmail.com</p></li>
                </ul>
            </div>
        </div>
    )
}

export default Slide;