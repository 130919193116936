import './App.css';
import React, {useState} from 'react';
import Home from './pages/home/Home';
import Aoa from './pages/404/Aoa';
import About from './pages/about/About';
import Contacto from './pages/contacto/Contacto';
import Modal from './componentes/modal/Modal';
import {Route,Routes} from 'react-router-dom';

import Header from './componentes/header/Header';
import Footer from './componentes/footer/Footer';

function App() {
  const [estadoModal,cambiaEstadoModal] = useState({type:null,on:false,id:null});
  return (
    <div id="mainT">
      <Modal estado={estadoModal} cambiarEstado={cambiaEstadoModal}></Modal>
      <Header></Header>
      <Routes>
        <Route path='' element={<Home />}></Route>
        <Route path='/' element={<Home />}></Route>
        <Route path='/Home' element={<Home />}></Route>
        <Route path='/About' element={<About />}></Route>
        <Route path='/Contacto' element={<Contacto />}></Route>
        <Route path='*' element={<Aoa />}></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
