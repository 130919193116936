import React, { useEffect, useState } from 'react';
import Menu from '../menu/Menu';
import './header.css';
import { Link } from 'react-router-dom';

const Header = (props) => {
  const [colors,cambiaColores] = useState({color1:'#fff', color2:'#fff', color3: '#fff'});

  useEffect(()=>{
    const header = document.getElementById('header');;
    window.onscroll = (e) => {
      const logo = document.getElementById('logo');
      const header = document.getElementById('header');
      if(window.scrollY > 0){
        logo.classList.add('logoS'); logo.classList.remove('logoL')
        header.classList.add('bg_w','txt_col')
        cambiaColores({color1:'#B41C2D', color2:'#312E82', color3: '#BE1622'})
      }else{
        logo.classList.add('logoL'); logo.classList.remove('logoS')
        header.classList.remove('bg_w','txt_col')
        cambiaColores({color1:'#fff', color2:'#fff', color3: '#fff'})
      }
    };

    // escuchando el alto de la ventana
    function checkdimension (){
      cambiaColores({color1:'#B41C2D', color2:'#312E82', color3: '#BE1622'})
      header.style.top = 'auto';
      header.style.bottom = '0';
      window.addEventListener('resize',()=>{
          console.log('window.innerHeight');
          /* header.style.top = window.innerHeight - header.clientHeight; */
          header.style.bottom = '0';
          header.style.top = 'auto';
      },true)
    }
    const h = window.innerWidth < 701 ? checkdimension():null;
  })
  const color1 = colors.color1;
  const color2 = colors.color2;
  const color3 = colors.color3;
  
  return(
    <header id='header' className=''>
      <Link to={'/Home'}>
        <div id="logo" className="header_brand logo logoL">
        <svg width="100%" height="100%" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M157.145 52.7844H134.285C133.153 52.7844 132.07 53.2383 131.272 54.0422L108.013 77.5469C106.356 79.2204 103.649 79.2258 101.987 77.5469L78.7008 54.0422C77.9024 53.2383 76.8195 52.7844 75.6875 52.7844H52.1172H21.6945C19.3539 52.7844 17.4563 54.6821 17.4563 57.0227V153.202C17.4563 155.542 19.3539 157.44 21.6945 157.44H47.075C47.1898 157.44 47.3047 157.44 47.4195 157.456C48.9672 157.593 50.5313 157.67 52.1172 157.67C80.5328 157.67 103.671 135.067 104.535 106.859C104.568 105.788 105.016 104.77 105.782 104.01L132.776 77.1805C135.45 74.5227 140.005 76.4149 140.005 80.1883V153.316C140.005 155.657 141.903 157.555 144.244 157.555H152.912C155.252 157.555 157.15 155.657 157.15 153.316V74.3532C157.15 72.0126 159.048 70.1149 161.388 70.1149H188.081C190.422 70.1149 192.32 68.2172 192.32 65.8766V57.0282C192.32 54.6875 190.422 52.7899 188.081 52.7899H157.15L157.145 52.7844ZM52.4289 140.055C52.3742 140.055 52.3141 140.055 52.2594 140.055H39.282C36.9414 140.055 35.0438 138.157 35.0438 135.816V74.3532C35.0438 72.0126 36.9414 70.1149 39.282 70.1149H52.0297V70.1696H54.2227C54.3211 70.1696 54.425 70.1696 54.5234 70.1805C72.8602 71.2633 87.3961 86.4774 87.3961 105.088C87.3961 123.698 71.7391 140.06 52.4234 140.06L52.4289 140.055Z" 
              fill="url(#paint0_linear_272_2323)"/>
              <path d="M189.355 140.055H177.751C176.138 140.055 174.83 141.362 174.83 142.975V154.58C174.83 156.193 176.138 157.5 177.751 157.5H189.355C190.968 157.5 192.276 156.193 192.276 154.58V142.975C192.276 141.362 190.968 140.055 189.355 140.055Z" 
              fill={color1}/>
              <defs>
              <linearGradient id="paint0_linear_272_2323" x1="17.4563" y1="105.224" x2="192.314" y2="105.224" gradientUnits="userSpaceOnUse">
              <stop stop-color={color2}/>
              <stop offset="1" stop-color={color3}/>
              </linearGradient>
              </defs>
        </svg>
        </div></Link>
        <Menu />
    </header>
)
}

export default (Header);
